import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-4" }
const _hoisted_3 = { class: "col-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tether_calculator = _resolveComponent("tether-calculator")!
  const _component_tether_chart = _resolveComponent("tether-chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tether_calculator, {
        flat: "",
        activeTab: _ctx.$route.query?.tab
      }, null, 8, ["activeTab"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_tether_chart)
    ])
  ]))
}