
import { defineComponent } from "vue";
import TetherCalculator from "@/components/Shared/TetherCalculator/TetherCalculator.vue";
import TetherChart from "@/components/Shared/TetherChart.vue";

export default defineComponent({
  name: "HomeDesktop",
  components: {
    TetherCalculator,
    TetherChart,
  },
});
